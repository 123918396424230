import {
  AfterContentChecked, ChangeDetectorRef,
  Component, OnInit, ViewChild, ViewContainerRef
} from "@angular/core";
import { Subscription } from "rxjs";
import { LoaderService } from "./services/loader/loader.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "./services/common/common.service";
import { AppInitService } from "./services/app-init/app-init.service";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from "./services/sidenav.service";
import { NgxSpinnerService } from "ngx-spinner";
import { DropDown } from "./shared-components/common-interface";
declare let pendo: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})

export class AppComponent implements OnInit, AfterContentChecked {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));
  @ViewChild('panel', { static: true }) private sidePanel: MatSidenav;
  @ViewChild('content', { static: true, read: ViewContainerRef }) private vcf: ViewContainerRef;
  title = "inmar-app";
  private subscription: Subscription;
  isLogin: any;
  isExportLoader: any;
  public pathInfo;
  constructor(
    private loader: LoaderService,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private commonserv: CommonService,
    private appInit: AppInitService,
    private breakpointObserver: BreakpointObserver,
    private sidenavService: SidenavService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService

  ) {
    this.subscription = this.loader.loaderState.subscribe((val) => {
      if (this.isExportLoader === false) {
        this.spinner.hide();
      } else {
        val == true ? this.spinner.show() : this.spinner.hide()

      }
    });
    this.loader.showExportLoader.subscribe(response => {
      if (response === false) {
        this.isExportLoader = false;
      }
    });
    this.appInit.Envronment$.subscribe(res => {
      localStorage.setItem('baseUrl', JSON.stringify(res));
      this.route.queryParams.subscribe((params) => {
        if (params['token']) {
          this.getUserInfo();
        }
      });
    });
  }

  ngOnInit() {
    this.sidenavService.setPanel(this.sidePanel);
    this.sidenavService.setContentVcf(this.vcf);
    this.isLogin = localStorage.getItem("isLogin");
    window.onbeforeunload = function () {
      return "Your work will be lost.";
    };

    if (sessionStorage.getItem("api_token")) {
      this.getUserInfo1()
      this.allFunction();
    }
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  allFunction() {
    this.getCustomUserList();
    this.getCustomDeductionOptionList();
    this.getCustomCreditLineItemList();
    this.getCustomProcessingStatusList();
    this.getCustomItemizationStatusList();
    this.getDisputeStatusList();
    this.getCustomerERPIdList();
    this.getDocumentTypeList();
    this.getProductConfigList();
    this.getCustomerDetails();
    this.userList();
    this.loader.userInfo.subscribe((data: any) => {
      if (data && Object.keys(data).length !== 0) {
        if (data.trade_promotions) {
          this.getPromotionDropdownDetails();
        }
      }
    });
  }

  getCustomUserList() {
    this.commonserv.userList(0).subscribe((res: any) => {
      let multiLevelApproval;
      this.loader.userInfo.subscribe((data: any) => {
        if (data && Object.keys(data).length !== 0) {
          multiLevelApproval = data.multi_level_approval;
          var approverList: any = [];
          var multiLevelApproverList: any = [];
          var processorList: any = [];
          for (let item of res['users_data']) {
            if (multiLevelApproval == true && item.UserType === 2 && item.MultiLevelApprover == false) {
              multiLevelApproverList.push(item);
            } else {
              approverList.push(item);
            }
            if (item.UserType === 0 || item.UserType === 1) {
              processorList.push(item);
            }
          }
          this.loader.approverCustomListForMemoChat.next(res['users_data']);
          approverList = multiLevelApproval ? multiLevelApproverList : approverList;
          var approverCusList: any = [];
          var processorcustomOptions: any = [];
          if (res.result != 0) {
            for (let item of approverList) { //Approver list
              if (item.Active == true) {
                var name = item.LastName == 'Unassigned' ? item.FirstName : item.FirstName + ' ' + item.LastName
                approverCusList.push({
                  id: item.Id,
                  name: name,
                  profileIcon: item.ProfilePictureLink ? item.ProfilePictureLink : 'assets/inmar-icon/ICONS/user-circle.svg'
                })
              }
            }
            for (let item of processorList) { //Processor list
              var name = item.LastName == 'Unassigned' ? item.FirstName : item.FirstName + ' ' + item.LastName
              if (item.Active == true) {
                processorcustomOptions.push({
                  id: item.Id,
                  name: name,
                  profileIcon: item.ProfilePictureLink ? item.ProfilePictureLink : 'assets/inmar-icon/ICONS/user-circle.svg'
                })
              }
            }
          }
          this.loader.approverCustomList.next(approverCusList);
          this.loader.processorCustomList.next(processorcustomOptions);
        }
      });
    });
  }

  getProductConfigList() {
    var data = {}
    this.commonserv.getProductConfigDetails(data, []).subscribe((res: any) => {
      this.loader.productGroupList.next(res.data.ProductGroup);
      this.loader.customerSegmentList.next(res.data.CustomerSegment);
    });
  }

  userList() {
    this.commonserv.searchUser('').subscribe((res: any) => {
      this.loader.userList.next(res.users_data);
    })
  }

  getUserInfo() {
    var data = {}
    this.commonserv.getUserInfo(data).subscribe((res: any) => {
      this.loader.userInfo.next(res);
      if (res.user_type == 1) {
        this.router.navigate(['/manage-deductions/new-remitance']);
        localStorage.setItem("user", "")
      } else {
        if (((res.is_approver_level_1 == true) && res.multi_level_approval == true) || res.multi_level_approval == false) {
          localStorage.setItem("user", res.first_name + ' ' + res.last_name)
          localStorage.setItem("user_id",res.user_id);
        }
        this.router.navigate(['/manage-deductions/approvals'])
      }
    });
  };

  getUserInfo1() {
    var data = {}
    this.commonserv.getUserInfo(data).subscribe((res: any) => {
      this.loader.userInfo.next(res);
      pendo.initialize({
        visitor: {
          id: res.user_name// Required if user is logged in, default creates anonymous ID
        },
        account: {
          // id: 'inmarAccountId' // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
          id: res.tenant_name
        }
      });
    });
  }

  // custom component changes
  getCustomDeductionOptionList() {
    let deductionOptions: any = [];
    this.commonserv.getDeductionTypeDetails({})
      .subscribe((res: DropDown) => {
        for (let item of res['deductions_type_list']) {
          deductionOptions.push({
            id: item.type_id,
            name: item.type,
          })
        }
      });
    this.loader.deductionCustomList.next(deductionOptions);
  }

  getPromotionDropdownDetails() {
    this.commonserv.getPromotionDropdownData().subscribe((res) => {
      const transformedData = this.transformData(res);
      this.loader.setPromotionDropdownData(transformedData);
    })
  };

  transformData(data: any) {
    const transformArray = (arr: string[]) => arr.map(item => ({ id: item, name: item }));
    return {
      spend_type: transformArray(data.spend_type),
      brand: transformArray(data.brand),
      product_group: transformArray(data.product_group),
      product_category: transformArray(data.product_category),
      promotion_status: transformArray(data.promotion_status),
    };
  };

  getCustomCreditLineItemList() {
    let creditLineOptions: any = [];
    let brandListOptions: any = [];
    let groupListOptions: any = [];
    let categoryListOptions: any = [];
    let segmentListOptions: any = [];

    this.commonserv.getCreditLineItemdetails({}).subscribe((res: DropDown) => {
      for (let item of res['credit']) {
        if (item.name !== "Multiple" && item.name !== "Review") {
          creditLineOptions.push({
            id: item.id,
            name: item.name,
          })
        }
      }
      this.loader.creditLineCustomItemList.next(creditLineOptions);

      res['brand'].forEach((el, i) => {
        if (el !== "Multiple" && el !== "Review") {
          brandListOptions.push({
            id: el,
            name: el,
          })
        }
      });
      this.loader.brandCustomList.next(brandListOptions);

      res['group'].forEach((el, i) => {
        if (el !== "Multiple" && el !== "Review") {
          groupListOptions.push({
            id: el,
            name: el,
          })
        }
      });
      this.loader.groupCustomList.next(groupListOptions);

      res['category'].forEach((el, i) => {
        if (el !== "Multiple" && el !== "Review") {
          categoryListOptions.push({
            id: el,
            name: el,
          })
        }
      });
      this.loader.categoryCustomList.next(categoryListOptions);

      res['segment'].forEach((el, i) => {
        if (el !== "Multiple" && el !== "Review" && el !== "") {
          segmentListOptions.push({
            id: el,
            name: el,
          })
        }

      });
      this.loader.segmentCustomList.next(segmentListOptions);
    });
  }

  getCustomProcessingStatusList() {
    let proccesorStatusCustomList: any = [];
    this.commonserv.getProcessingStatusDetails({}).subscribe((res: any) => {
      for (let item of res['processing_status_list']) {
        proccesorStatusCustomList.push({
          id: item,
          name: item,
          disabled: item !== "Split" && item !== "Not Applicable" ? false : true
        });
      }
      this.loader.proccesorStatusCustomList.next(proccesorStatusCustomList);
    });
  }

  getCustomItemizationStatusList() {
    let itemizedStatusOptions: any = [];
    this.commonserv.getItemizationStatusDetails({}).subscribe((res: any) => {
      if (res.result != 0) {
        res['Itemization_status_list'].forEach((el, i) => {
          itemizedStatusOptions.push({
            id: el,
            name: el
          })
        });
      }
    });
    this.loader.itemizationCustomStatusList.next(itemizedStatusOptions);
  }

  getCustomerDetails() {
    var data = {};
    this.commonserv.getCustomerDetails(data).subscribe((res: any) => {
      this.loader.customerOptions.next(res.customer_list);
    });
  }

  getDisputeStatusList() {
    let disputeStatusOptions: any = [];
    this.commonserv.getDisputeStatusDetails({}).subscribe((res: any) => {
      if (res.result != 0) {
        res['dispute_status_list'].forEach((el, i) => {
          disputeStatusOptions.push({
            id: el,
            name: el,
          })
        });
      }
    });
    this.loader.disputeStatusList.next(disputeStatusOptions);
  }

  getDocumentTypeList() {
    let documentTypeListOptions: any = [];
    this.commonserv.fetchDocumentType({}).subscribe((res: any) => {
      if (res.result != 0) {
        res.document_type.forEach((el, i) => {
          documentTypeListOptions.push({
            id: el.type_id,
            name: el.name,
          })
        });
        this.loader.documentTypeListOptions.next(documentTypeListOptions);
      }
    });
  }

  getCustomerERPIdList() {
    let customerERPIdOptions: any = [];
    this.loader.customerERPIdList.next(customerERPIdOptions);
  }
}